$hero-height: 130vh;

.hero-container {
  margin: 0;
  padding: 0;
  $text-top: 40vh;

  .hero-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 54px;
    font-family: $secondary-font;
    font-weight: 900;
    line-height: 56px;
  }

  .hero_image-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $hero-height;

    .hero_image {
      user-select: none;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      object-fit: cover;
      transition: transform 200ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .title-clipper {
      position: relative;
      height: $hero-height;
      width: 100%;
    }
    .clip {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      pointer-events: none;
    }
    .title-holder {
      position: fixed;
      top: $text-top;
      width: 100vw;
      z-index: 3;
      display: flex;
      justify-content: center;
      text-shadow: 0 30px 60px rgba(#000, 0.5);

      .hero-title {
        color: white;
        span {
          filter: drop-shadow(0 0 2px rgba(#000, 0.2));
        }
      }
    }
  }

  .hero-title-wrapper {
    margin-top: -$hero-height;
    position: absolute;
    z-index: 1;
    height: calc(#{$hero-height} + 40vh);
    width: 100vw;

    .title-holder {
      position: sticky;
      top: $text-top;
      width: 100vw;
      display: flex;
      justify-content: center;

      .hero-title {
        color: black;
      }
    }
  }

  .dark-title {
    .title-3 {
      color: $c-primary;
    }
  }

  .hero-content {
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
    padding: 0 40px;
    padding-bottom: 60px;

    margin-top: 45vh;

    .text {
      opacity: 0.8;
      font-size: 17px;
      letter-spacing: 1.5%;
      line-height: 160%;
    }
  }
}

@media (min-width: $media-sm) {
  .hero-container {
    .hero-title {
      font-size: 70px;
      line-height: 70px;
    }
  }
}

@media (min-width: $media-md) {
  .hero-container {
    .hero-title {
      font-size: 84px;
      line-height: 86px;
    }
  }
}

@media (min-width: $media-lg) {
  .hero-container {
    $text-top: 30vh;

    .hero-title {
      font-size: 120px;
      line-height: 110px;
    }

    .hero_image-wrapper {
      .title-holder {
        top: $text-top;
        text-shadow: 0 60px 120px rgba(#000, 0.3);
      }
    }

    .hero-title-wrapper {
      height: calc(#{$hero-height} + 65vh);
      .title-holder {
        top: $text-top;
      }
    }
    .hero-content {
      margin-top: 70vh;

      .text {
        font-size: 18px;
        max-width: 500px;
        letter-spacing: 1%;
        line-height: 150%;
      }

      .inquire {
        margin-top: 10px;
        font-size: 18px;
        padding: 16px 80px 14px 80px;
        background: $c-primary;
        border: none;
        border-radius: 40px;
        color: $c-white;
        font-family: $secondary-font;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        box-shadow: 0 4px 16px 8px rgba($c-primary, 0.2);
      }
    }
  }
}

@media (min-width: $media-xl) {
  .hero-container {
    .hero-title {
      font-size: 130px;
      line-height: 120px;
    }
  }
}
