$footer-height: 640px;

.main {
  background-color: $c-white;
}

body {
  margin-bottom: $footer-height;
}

.contact-container {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: $footer-height;
  background: #2b4650;
  text-align: center;
  color: #fff;
  max-height: 100vh;

  .title {
    font-weight: 900;
    font-family: $secondary-font;
    font-size: 36px;
    line-height: 36px;
    margin-top: 50px;
  }

  .description {
    font-size: 13px;
    opacity: 0.7;
    padding: 0 30px;
  }

  .contact-button {
    margin: 0 auto;
    font-size: 17px;
    padding: 14px 40px 12px 40px;
    background: $c-white;
    border: none;
    border-radius: 40px;
    color: $c-black;
    font-family: $secondary-font;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    font-weight: 600;
    box-shadow: 0 4px 16px 8px rgba($c-primary, 0.2);
    text-decoration: none;
  }

  .contact-items {
    .contact-item {
      margin: 30px 0;
      a {
        display: flex;
        flex-direction: column;
        color: $c-white;
        font-size: 14px;
        text-decoration: none;
        max-width: 300px;
        margin: 0 auto;

        i {
          opacity: 0.3;
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(#fff, 0.2);
    .logo {
      padding: 15px;
      img {
        height: 40px;
      }
    }

    .copyright {
      font-weight: 300;
      font-size: 12px;
      opacity: 0.3;
    }
  }
}

@media (min-width: $media-md) {
  $footer-height: 580px;

  body {
    margin-bottom: $footer-height;
  }

  .contact-container {
    height: $footer-height;
    .title {
      font-size: 52px;
      line-height: 52px;
      margin-top: 60px;
    }

    .description {
      font-size: 14px;
    }

    .contact-items {
      padding-top: 50px;
      padding-bottom: 50px;
      display: flex;
      justify-content: space-between;
      max-width: 800px;
      margin: 0 auto;

      .contact-item {
        margin: 30px 0;
        a {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          text-decoration: none;
          max-width: 300px;
          margin: 0 auto;

          &:hover {
            span {
              color: rgba(#fff, 0.8);
            }
          }

          span {
            transition: color 200ms ease;
            color: rgba(#fff, 0.3);
          }

          i {
            opacity: 1;
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .bottom {
      flex-direction: row;
      border-top: none;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .logo {
        margin-top: -20px;
        img {
          height: 45px;
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  $footer-height: 610px;

  body {
    margin-bottom: $footer-height;
  }

  .contact-container {
    height: $footer-height;
    .title {
      font-size: 70px;
      line-height: 70px;
      margin-top: 80px;
    }

    .description {
      padding: 5px;
      font-size: 15px;
    }

    .contact-items {
      margin-top: 0px;
    }
  }
}

@media (min-width: $media-xl) {
  $footer-height: 660px;

  body {
    margin-bottom: $footer-height;
  }

  .contact-container {
    height: $footer-height;
    .title {
      font-size: 92px;
      line-height: 92px;
      margin-top: 100px;
    }

    .description {
      padding: 5px;
      font-size: 15px;
    }

    .contact-items {
      padding-left: 20px;
    }
  }
}
